import React, { useState } from 'react'
import './nav.scss'
import WhiteLogo from '../../images/logo/white-logo.svg'
import RedLogo from '../../images/logo/red-logo.svg'
import { Link } from 'gatsby'
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

const Nav = ({
    isWhite
}) => {

    const [navOpen, setNavOpen] = useState(false)
    const [solutionsMenu, setSolutionsMenu] = useState(false)
    const [useCaseMenu, setUseCaseMenu] = useState(false)

    const toggleNav = () => {
        setNavOpen(true)
    }

    const closeNav = () => {
        setNavOpen(false)
    }

    const toggleSolutionsMenu = () => {
        setSolutionsMenu(!solutionsMenu)
        setUseCaseMenu(false)
    }

    const toggleUseCaseMenu = () => {
        setSolutionsMenu(false)
        setUseCaseMenu(!useCaseMenu)
    }

    return (
        <nav className={`py-3 ${isWhite ? "nav-border" : "nav-bg"}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-6">
                        <Link to="/"><img src={isWhite ? RedLogo : WhiteLogo} alt="logo" style={{ maxWidth: '190px' }} /></Link>


                        <div className="d-md-inline-flex d-none ml-5 pl-4">
                            <div className="position-relative solutions-dropdown">
                                <p style={{fontSize: '1.125rem'}} className={`mr-4 font-weight-normal cursor mb-0 header ${isWhite ? "" : "text-white"}`}>Solutions</p>

                                <div className={`solutions-dropdown-content p-5 ${isWhite ? "blue-bg" : "white-bg"}`}>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <Link to="/digital"><p className={`small mb-2 font-weight-bold cursor ${isWhite ? "link-text" : "black-link-text"}`}>Digital</p></Link>

                                            <p className="small text-secondary mb-0">Digitize your business operations and be prepared to thrive in the digital market.</p>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Link to="/transformation"><p className={`small mb-2 font-weight-bold cursor ${isWhite ? "link-text" : "black-link-text"}`}>Transformation</p></Link>

                                            <p className="small text-secondary mb-0">Increase your business chance of survival and achieve long-term success with all round transformation</p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <Link to="/intelligent-automation"><p className={`small mb-2 font-weight-bold cursor ${isWhite ? "link-text" : "black-link-text"}`}>Intelligent Automation</p></Link>

                                            <p className="small text-secondary mb-0">Extend your business horizons with the amazing power of intelligent automation technologies</p>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Link to="/human-capital-development"><p className={`small mb-2 font-weight-bold cursor ${isWhite ? "link-text" : "black-link-text"}`}>Human Capital Development</p></Link>

                                            <p className="small text-secondary mb-0">Build your workforce and equip them with skills to build your business.</p>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6 mb-3">
                                            <Link to="/risk-management"><p className={`small mb-2 font-weight-bold cursor ${isWhite ? "link-text" : "black-link-text"}`}>Risk Management</p></Link>

                                            <p className="small text-secondary mb-0">Take precautionary steps to protect your business, customers and workforce from potential risks</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="position-relative usecases-dropdown">
                                <p style={{fontSize: '1.125rem'}} className={`mr-4 font-weight-normal cursor mb-0 header ${isWhite ? "" : "text-white"}`}>Usecases</p>

                                <div className={`usecases-dropdown-content p-4 ${isWhite ? "blue-bg" : "white-bg"}`}>
                                    <Link to="/digital-usecase"><p className={`text-capitalize small mb-2 cursor ${isWhite ? "link-text" : "black-link-text"}`}>Digital</p></Link>
                                    <Link to="/transformation-usecase"><p className={`text-capitalize small mb-2 cursor ${isWhite ? "link-text" : "black-link-text"}`}>Transformation</p></Link>
                                    <Link to="/intelligent-automation-usecase"><p className={`text-capitalize small mb-2 cursor ${isWhite ? "link-text" : "black-link-text"}`}>Intelligent automation</p></Link>
                                    <Link to="/human-capital-development-usecase"><p className={`text-capitalize small mb-2 cursor ${isWhite ? "link-text" : "black-link-text"}`}>Human capital development</p></Link>
                                    <Link to="/security-usecase"><p className={`text-capitalize small mb-2 cursor ${isWhite ? "link-text" : "black-link-text"}`}>Risk Management</p></Link>
                                </div>
                            </div>


                            <Link to="/insights-posts"><p style={{fontSize: '1.125rem'}} className={`mr-4 font-weight-normal cursor mb-0 header ${isWhite ? "text-dark" : "text-white"}`}>Insights</p></Link>
                        </div>

                    </div>

                    {/* mobile */}
                    <div className="d-md-none d-block col-md-12 col-6 text-right">
                        <div>
                            <h4 onClick={() => toggleNav()} className={`cursor ${isWhite ? "text-dark" : "text-white"}`}>&#9776;</h4>
                        </div>
                        <div className="d-md-none d-block">
                            <div id="mySidenav" className={`sidenav ${navOpen ? "w-100" : ""}`}>
                                <p className="closebtn menu-header" onClick={() => closeNav()}>&times;</p>
                                <p onClick={() => toggleSolutionsMenu()} className={`menu-header mt-4 font-weight-bold cursor mb-0 header ${isWhite ? "" : "text-white"}`}>Solutions</p>
                                {/* solutions sub menu */}
                                <div className={`${solutionsMenu ? 'd-block' : 'd-none'}`}>
                                    <Link to="/digital"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Digital</p></Link>
                                    <Link to="/transformation"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Transformation</p></Link>
                                    <Link to="/intelligent-automation"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Intelligent Automation</p></Link>
                                    <Link to="/human-capital-development"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Human Capital Development</p></Link>
                                    <Link to="/risk-management"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Risk Management</p></Link>
                                </div>

                                <p onClick={() => toggleUseCaseMenu()} className={`font-weight-bold cursor mb-0 header menu-header ${isWhite ? "" : "text-white"}`}>Usecases</p>

                                {/* usecase sub menu */}
                                <div className={`${useCaseMenu ? 'd-block' : 'd-none'}`}>
                                    <Link to="/digital-usecase"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Digital</p></Link>
                                    <Link to="/transformation-usecase"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Transformation</p></Link>
                                    <Link to="/intelligent-automation-usecase"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Intelligent automation</p></Link>
                                    <Link to="/human-capital-development-usecase"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Human Capital Development</p></Link>
                                    <Link to="/security-usecase"><p className="text-capitalize text-white text-center inner-menu" style={{ fontSize: '20px' }}>Risk Management</p></Link>
                                </div>
                                <Link to="/insights-posts"><p className={`font-weight-bold cursor mb-0 header menu-header ${isWhite ? "" : "text-white"}`}>Insights</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Nav
